import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import axios from 'axios';
import { databaseDate, versionCheck } from '@compass/utils';
import panelSchema from '../utils/panelSchema';
import { columns } from './helpers';
import { stickyColumns } from '../views/HardwareUpload/HardwareGrid/HardwareGridHelper';

const devJobList = [
  618, 622, 624, 626, 627, 605, 611, 617, 648, 623, 610, 646, 658, 556, 661,
  649, 675, 653, 654, 663, 681, 637, 679, 671, 685, 689, 682, 645, 699, 697,
  700, 704, 692, 693, 684
].sort();

const isDev = process.env.NODE_ENV === 'development';

class HardwareUploadStore {
  isRefreshing = false;
  jobNumber = '';
  job = { name: '' };
  revitYear = '';
  showSettings = false;
  scrollToColumn = 0;
  scrollToRow = 0;
  currentHardReject = -1;
  schedules = [];
  disabledRows = [];

  recentChanges = [];
  recentChangesDialog = false;

  currentHardwareSchedule = [];
  jobSettingsDialog = false;
  debugMode = true;

  devJobs = devJobList;

  constructor(rootStore, options) {
    this.rootStore = rootStore;

    if (options) {
      if ('debugMode' in options) {
        runInAction(() => {
          this.debugMode = options.debugMode;
        });
      }
    }

    makeAutoObservable(this, {
      rootStore: false
    });

    window.addEventListener(
      'beforeunload',
      () => {
        this.stopServer();
        // Do something
      },
      false
    );
    window.onbeforeunload = () => {
      this.stopServer();
      // Do something
    };

    reaction(
      () => this.allDataEnabledColumns,
      columns => {
        if (columns && columns.length) {
        }
      },
      { fireImmediately: false }
    );
  }

  setFromLocalStorage = (variable, data) => {
    this[variable] = data;
  };

  dispose = () => {
    this.syncStoragePanelDispose && this.syncStoragePanelDispose();
    this.syncStorageJobNumberDispose && this.syncStorageJobNumberDispose();
    this.checkStorageDispose && this.checkStorageDispose();
  };

  setDisabledRows = rowIndex => {
    const currentDisabled = new Set([...this.disabledRows, rowIndex]);
    this.disabledRows = Array.from(currentDisabled);
  };

  removeDisabledRows = rowIndex => {
    this.disabledRows = this.disabledRows.filter(item => item !== rowIndex);
  };

  setIsRefreshing = refreshing => {
    this.isRefreshing = refreshing;
  };

  getHardwareSchedule = async () => {
    if (this.validRevitURL) {
      try {
        const markSchedule = await axios.get(
          this.revitURL + 'schedules/PCS Mark No. Parameters'
        );
        runInAction(() => {
          const checkData = markSchedule.data[0];
          if (!('Hardware Order' in checkData)) {
            throw Error('Schedule is missing column (Hardware Order)');
          } else {
            this.currentHardwareSchedule = markSchedule.data;
          }
        });
      } catch (error) {
        if (error.message === 'Request failed with status code 404') {
          this.rootStore.notificationStore.addNotification({
            message:
              'Error Getting Mark Schedule! Check that your mark schedule matches exactly: PCS Mark No. Parameters',

            options: { persist: true, variant: 'error' }
          });
        } else {
          this.rootStore.notificationStore.addNotification({
            message: `Error Getting Mark Schedule: ${
              error.message ? error.message : String(error)
            } `,

            options: { persist: true, variant: 'error' }
          });
        }
        console.error('ERROR - getMarkSchedule:', error);
      }
    }
  };

  stopServer = async () => {
    if (isDev) return;
    try {
      this.debugMode && console.log('Trying to kill server');
      await axios.get(this.rootStore.uiStore.revitURL + 'stop-server');
    } catch (error) {
      console.warn(error);
    }
  };

  refresh = async () => {
    let status;
    const { getStatus, setStatus } = this.rootStore.uiStore;
    try {
      this.setIsRefreshing(true);
      status = await getStatus();

      if (status > 2017) {
        setStatus(status);
        const pluginVersion = await axios.get(
          this.rootStore.uiStore.revitURL + 'version'
        );
        if (!versionCheck(pluginVersion.data, '22.8.0'))
          throw Error(
            `Data Upload v${pluginVersion.data} is out of date. Update from the Compass Plugin Manager to continue`
          );
        if (this.rootStore.uiStore.jobNumber) {
          await this.getHardwareSchedule();
        }
      }
    } catch (err) {
      if (err.response && err.response.data.includes('KeyError: version')) {
        this.rootStore.notificationStore.addNotification({
          message:
            'As of 8/12/22, you must update your Data Upload plugin from the Compass Plugin Manager to continue using',
          options: { persist: true, variant: 'error' }
        });
      } else {
        this.rootStore.notificationStore.addNotification({
          message: `Error Connecting to Revit: ${
            err.message ? err.message : String(err)
          }`,

          options: { persist: true, variant: 'error' }
        });
      }

      console.error('Error getting status', err);
    }
    return;
  };

  nextHardReject = () => {
    if (this.hardware.hardReject.length > 0) {
      if (this.currentHardReject === this.hardware.hardReject.length - 1) {
        this.currentHardReject = 0;
      } else {
        this.currentHardReject += 1;
      }
      const { rowIndex, columnIndex } =
        this.hardware.hardReject[this.currentHardReject];
      console.log('nextHardReject', { rowIndex, columnIndex });
      this.setScrollToColumn(columnIndex);
      this.setScrollToRow(rowIndex + 1);
    }
  };

  scrollToError = ({ columnIndex, rowIndex }) => {
    console.log('scrollToError', { columnIndex, rowIndex });
    const foundErrorIndex = this.hardwareCount.hardReject.findIndex(
      cell => cell.rowIndex === rowIndex && columnIndex === cell.columnIndex
    );
    if (foundErrorIndex > -1) {
      this.currentHardReject = foundErrorIndex;
    }
    this.setScrollToColumn(columnIndex);
    this.setScrollToRow(rowIndex);
  };

  setScrollToColumn = value => {
    if (isNaN(value)) value = 0;
    this.scrollToColumn = Math.min(
      this.enabledColumns.length - 1,
      parseInt(value, 10)
    );
  };
  setScrollToRow = value => {
    if (isNaN(value)) value = 0;
    this.scrollToRow = Math.min(this.rowCount - 1, parseInt(value, 10));
  };

  setUpdatedHDWR = updatedValues => {
    this.recentChanges = updatedValues;
    console.log('recentChanges', toJS(this.recentChanges));
  };

  get validRevitURL() {
    return this.rootStore.uiStore.validRevitURL;
  }

  get revitURL() {
    return this.rootStore.uiStore.revitURL;
  }

  get formattedHardwareSchedule() {
    let temp = this.currentHardwareSchedule.slice().map(obj => {
      let parsedOrder = obj['Hardware Order']
        .split(/[- ()]/)
        .filter(part => part !== '');

      let hardwareType = parsedOrder?.[0] || '';
      let hardwareOrderNumber = parsedOrder?.[1] || '';
      let description = parsedOrder?.[2] || '';

      return Object.assign(
        {},
        {
          orderNumber: hardwareOrderNumber,
          hardwareType: hardwareType,
          description: description,
          jobNumber: this.rootStore.uiStore.jobNumber,
          orderCreatedDate: databaseDate()
        }
      );
    });

    return temp;
  }

  get allDataEnabledColumns() {
    return Array.from(new Set([...this.dataEnabledFormattedHardwareColumns]));
  }

  get dataEnabledFormattedHardwareColumns() {
    if (
      this.formattedHardwareSchedule &&
      this.formattedHardwareSchedule.length
    ) {
      const currentKeys = Object.keys(this.formattedHardwareSchedule[0]);

      const currentHDWRColumns = this.formattedHardwareSchedule
        .slice()
        .reduce((enabledHDWRColumns, currentHDWRData) => {
          for (const column of currentKeys) {
            const currentValue = currentHDWRData[column];
            if (currentValue && `${currentValue}` !== '0') {
              enabledHDWRColumns.add(column);
            }
          }

          return enabledHDWRColumns;
        }, new Set());
      return Array.from(currentHDWRColumns);
    }
    return [];
  }

  get enabledColumns() {
    return columns.slice().filter(({ variable }) => {
      if (stickyColumns.includes(variable)) return true;
      if (this.allDataEnabledColumns.includes(variable)) return true;

      return false;
    });
  }

  get HDWRDataToSend() {
    return this.HDWRWithUpdates.slice()
      .map(dbOrder => {
        const order = dbOrder['db-orderNumber'];
        const hdwrType = dbOrder['db-hardwareType'];
        const description = dbOrder['db-description'];
        const sendData = this.selectedHDWRData
          .filter(hdwr => {
            if (
              order === hdwr.orderNumber &&
              hdwrType === hdwr.hardwareType &&
              description === hdwr.description
            )
              return true;
            return false;
          })
          .map(hdwr => {
            return {
              hardwareType: hdwr.hardwareType,
              orderNumber: hdwr.orderNumber,
              description: hdwr.description,
              ...hdwr
            };
          });
        return sendData;
      })
      .flat();
  }

  get selectedHDWRData() {
    return this.hardware.validated
      .slice()
      .filter(
        (hardware, index) =>
          !this.disabledRows.includes(index) && !hardware.title
      );
  }

  get rowCount() {
    return this.currentHardwareSchedule.length;
  }

  get HDWRWithUpdates() {
    return this.compareToDB
      .slice(1)
      .filter(i => Object.keys(i).length > 0 && !i.title);
  }

  get compareToDB() {
    const diffComp = this.hardware.validated
      .map((revitHDWR, index) => {
        if (index === 0) return revitHDWR;
        const foundHDWR = this.serverHardware
          .slice()
          .find(
            p =>
              p.orderNumber === revitHDWR.orderNumber &&
              p.hardwareType === revitHDWR.hardwareType &&
              p.description === revitHDWR.description
          );

        if (foundHDWR) {
          const diff = Object.entries(revitHDWR)
            .filter(([revitHDWRKey, revitHDWRValue]) => {
              const currentDBValue = foundHDWR[revitHDWRKey];
              if (currentDBValue === undefined && currentDBValue === null) {
                if (
                  currentDBValue &&
                  revitHDWRValue &&
                  String(currentDBValue) !== String(revitHDWRValue)
                ) {
                  return true;
                }
              }
              return false;
            })
            .map(([revitHDWRKey, revitHDWRValue]) => {
              return {
                [`db-${revitHDWRKey}`]: foundHDWR[revitHDWRKey]
              };
            })
            .reduce((r, c) => Object.assign(r, c), {});

          return {
            ...diff
          };
        } else {
          const hdwr = this.selectedHDWRData.filter(
            p =>
              p.orderNumber === revitHDWR.orderNumber &&
              p.hardwareType === revitHDWR.hardwareType &&
              p.description === revitHDWR.description
          );

          const diff = Object.entries(hdwr)
            .filter(([revitHDWRKey, revitHDWRValue]) => {
              return revitHDWRValue;
            })
            .map(([revitHDWRKey, revitHDWRValue]) => {
              return {
                [`db-orderNumber`]: revitHDWRValue.orderNumber,
                [`db-hardwareType`]: revitHDWRValue.hardwareType,
                [`db-description`]: revitHDWRValue.description
              };
            })
            .reduce((r, c) => Object.assign(r, c), {});

          return { ...diff };
        }
      })
      .filter(hdwr => hdwr);
    return diffComp;
  }
  get columnKeys() {
    return this.enabledColumns.map(c => c.variable);
  }

  get hardwareSchedule() {
    return this.currentHardwareSchedule;
  }

  get currentErrorCell() {
    if (this.hardware.hardReject.length === 0) {
      return null;
    }

    if (!this.currentHardReject)
      return this.hardware.hardReject[this.currentHardReject];
    return null;
  }

  get hardwareCount() {
    return this.formattedHardwareSchedule.length;
  }

  get allHardware() {
    return this.hardware?.validated || [];
  }

  get hardware() {
    let hardReject = [];
    const formattedHDWR = [
      ...this.formattedHardwareSchedule
        .slice()
        .filter(order => order.orderNumber)
    ];

    const unordered = [
      ...new Map(
        formattedHDWR.map(item => [item['orderNumber'], item])
      ).values()
    ];

    const validated = unordered
      .slice()
      .sort((a, b) => {
        return a.orderNumber.localeCompare(b.orderNumber, undefined, {
          numeric: true
        });
      })
      .reduce((acc, order, currentIndex) => {
        const validationOrderNumber = panelSchema['hardwareOrderNumber'];
        const validationHardwareType = panelSchema['hardwareType'];
        const validationDescription = panelSchema['description'];

        const checkRegex =
          !!String(order.orderNumber).match(validationOrderNumber.regex) &&
          !!String(order.hardwareType).match(validationHardwareType.regex) &&
          !!String(order.description).match(validationDescription.regex);
        if (!checkRegex) {
          hardReject.push({
            rowIndex: currentIndex + 1,
            columnIndex: 0
          });
        }
        acc.push(order);
        return acc;
      }, []);

    validated.unshift({ title: 'Header' });

    return { validated: validated, hardReject: hardReject };
  }

  get serverHardware() {
    if (!this.rootStore.hardwareOrdersStore?.entities) return [];
    return this.rootStore.hardwareOrdersStore.entities
      .slice()
      .filter(
        hdwr =>
          parseInt(this.rootStore.uiStore.jobNumber) ===
          parseInt(hdwr.jobNumber)
      );
  }
}

export default HardwareUploadStore;
