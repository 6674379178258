import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@compass/react/ThemeProvider';
import { RootStoreProvider } from '@compass/react/RootStoreProvider';
import { NotificationProvider } from '@compass/react/NotificationProvider';

import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import appRootStore from './stores/rootStore.js';

let rootStore = appRootStore;

window.rootStore = rootStore;

ReactDOM.render(
  <RootStoreProvider rootStore={rootStore}>
    <ThemeProvider>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </ThemeProvider>
  </RootStoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
