import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import axios from 'axios';
import panelSchema from '../utils/panelSchema';
import {
  databaseDate,
  productTypeConversion,
  uniqueArray,
  versionCheck,
  isSameJobNumber
} from '@compass/utils';
import { parseMix, columns } from './helpers';
import { stickyColumns } from '../views/PCSUpload/PCSGrid/PCSGridHelper';
const devJobList = [
  618, 622, 624, 626, 627, 605, 611, 617, 648, 623, 610, 646, 658, 556, 661,
  649, 675, 653, 654, 663, 681, 637, 679, 671, 685, 689, 682, 645, 699, 697,
  700, 704, 692, 693, 684
].sort();

const isDev = process.env.NODE_ENV === 'development';

// const revitScheduleHeaderMap = {
//   'Control #': 'controlNumber',
//   'Crane Set-Up': 'craneSetupNumber',
//   'Installation Sequence': 'installationSequence',
//   'Erection Sequence': 'installationSequence',
//   'Leveling Bolt Description': 'installationSequence',
//   'Loose Bar Count': 'looseBarCount',
//   'Panel Weight': 'individualPanelWeight',
//   'Panel Weigeight': 'individualPanelWeight',
//   'RV IFC Date': 'rearViewIFC',
//   'Total Weld Inches': 'totalWeldInches',
//   Type: 'markNumber',
//   'Hardware Order': 'hardwareOrderNumber',
//   'Hardware Order #': 'hardwareOrderNumber',
//   'Product Type': 'skinNumber',
//   Length: 'panelLength',
// };
class PCSUploadStore {
  jobNumber = '';
  isRefreshing = false;
  job = { name: '' };
  revitYear = '';
  showSettings = false;
  scrollToColumn = 0;
  progress = 0;
  isUploading = false;
  scrollToRow = 0;
  currentHardReject = -1;
  currentSoftReject = -1;
  schedules = [];
  disabledRows = [];
  revitURL = '';

  recentChanges = [];
  recentChangesDialog = false;

  currentJobMarkSchedule = [];
  currentJobMaterialSchedule = [];
  currentJobSkinSchedule = [];

  jobSettingsDialog = false;
  revitScheduleDialog = false;
  debugMode = true;
  devJobs = devJobList;

  constructor(rootStore, options) {
    this.rootStore = rootStore;
    if (options) {
      if ('debugMode' in options) {
        runInAction(() => {
          this.debugMode = options.debugMode;
        });
      }
    }
    makeAutoObservable(this, {
      rootStore: false
    });

    window.addEventListener(
      'beforeunload',
      () => {
        this.stopServer();
        // Do something
      },
      false
    );
    window.onbeforeunload = () => {
      this.stopServer();
      // Do something
    };

    this.dupeNotificationDispose = reaction(
      () => this.panelCount.dupes,
      dupes => {
        console.log('DUPE', dupes);

        if (dupes.length > 0)
          this.rootStore.notificationStore?.addNotification({
            message:
              dupes.length > 1
                ? `(${dupes.length}) Duplicate Control Numbers: ${dupes.join(
                    ', '
                  )}`
                : `(${dupes[0]}) Duplicate Control Number!`,

            options: { persist: true, variant: 'error' }
          });
      },
      { fireImmediately: true }
    );

    this.materialErrorReactionDispose = reaction(
      () =>
        this.formattedMaterialSchedule && this.formattedMaterialSchedule.errors,
      async errors => {
        if (errors && errors.missingBase && errors.missingBase.length > 0) {
          const markTypes = errors.missingBase.join(', ');

          this.rootStore.notificationStore.addNotification({
            message: `Error finding base in the material schedule for the following marks: ${markTypes}`,

            options: { persist: true, variant: 'error' }
          });
        }
        if (
          errors &&
          errors.exceedTotalMixCubicYd &&
          errors.exceedTotalMixCubicYd.length > 0
        ) {
          const markTypes = errors.exceedTotalMixCubicYd.join(', ');

          this.rootStore.notificationStore.addNotification({
            message: `Error: Total Mix Sq Ft values exceed limits for the following marks. Check 'Material: Volume' units are in Cubic Yards instead of Cubic Feet: ${markTypes}`,

            options: { persist: true, variant: 'error' }
          });
        }
      }
    );

    this.skinScheduleErrorDispose = reaction(
      () => this.formattedSkinSchedule && this.formattedSkinSchedule.errors,

      async errors => {
        if (errors.missingSkins && errors.missingSkins.length > 0) {
          for await (const markType of errors.missingSkins) {
            this.rootStore.notificationStore.addNotification({
              message: `Error finding skin in the skin schedule for ${markType.markNumber}`,

              options: { persist: true, variant: 'error' }
            });
          }
        }
        if (errors.missingProdTypes && errors.missingProdTypes.length > 0) {
          for await (const skin of errors.missingProdTypes) {
            this.rootStore.notificationStore.addNotification({
              message: `Error: Missing Product Type for ${skin['Type']}`,

              options: { persist: true, variant: 'error' }
            });
          }
        }
      }
    );

    rootStore.app
      .service('panel-revit-upload')
      .on('panel-revit-progress', data => {
        if (data.userId === this.rootStore.authStore.currentUser.id) {
          this.setProgress(data.progress);
        }
      });
  }

  setProgress = progress => {
    this.progress += progress;
  };

  startUpload = () => {
    this.isUploading = true;
  };
  stopUpload = () => {
    this.isUploading = false;
    this.progress = 0;
  };

  openJobSettingsDialog = () => {
    this.jobSettingsDialog = true;
  };
  closeJobSettingsDialog = () => {
    this.jobSettingsDialog = false;
  };

  openRevitScheduleDialog = () => {
    this.revitScheduleDialog = true;
  };
  closeRevitScheduleDialog = () => {
    this.revitScheduleDialog = false;
  };

  setFromLocalStorage = (variable, data) => {
    this[variable] = data;
  };

  setIsRefreshing = refreshing => {
    this.isRefreshing = refreshing;
  };

  dispose = () => {
    this.dupeNotificationDispose && this.dupeNotificationDispose();
    this.syncStoragePanelDispose && this.syncStoragePanelDispose();
    this.syncStorageJobNumberDispose && this.syncStorageJobNumberDispose();
    this.checkStorageDispose && this.checkStorageDispose();
    this.skinScheduleErrorDispose && this.skinScheduleErrorDispose();
  };

  setDisabledRows = rowIndex => {
    const currentDisabled = new Set([...this.disabledRows, rowIndex]);
    this.disabledRows = Array.from(currentDisabled);
  };

  removeDisabledRows = rowIndex => {
    this.disabledRows = this.disabledRows.filter(item => item !== rowIndex);
  };

  openPluginManager = () => {
    this.showSettings = !this.showSettings;
  };

  getServerPanels = () => {
    const controlNumbers = this.formattedMarkSchedule
      .slice()
      .map(i => i.controlNumber);
    const query = {
      controlNumber: {
        $in: [...controlNumbers]
      },
      jobNumber: this.rootStore.uiStore?.jobNumber
    };

    this.rootStore.panelRevitStore?.find({ query });
  };

  getStatus = async () => {
    if (this.validRevitURL) {
      try {
        const { data: status } = await axios.get(this.revitURL + 'status');
        const { setStatus } = this.rootStore.uiStore;
        setStatus(status);
        return status;
      } catch (error) {
        if (error.response && error.response.data)
          this.rootStore.uiStore.setStatus(error.response.data);

        console.warn(error);
      }
    }
  };

  getJobNumber = async () => {
    if (this.validRevitURL) {
      try {
        const { data: jobNumber } = await axios.get(
          this.revitURL + 'jobNumber'
        );
        return jobNumber;
      } catch (error) {
        console.warn(error);
      }
    }
  };

  getMarkSchedule = async () => {
    if (this.validRevitURL) {
      try {
        const markSchedule = await axios.get(
          this.revitURL + 'schedules/PCS Mark No. Parameters'
        );

        runInAction(() => {
          if (markSchedule.data && markSchedule.data.length) {
            const checkData = markSchedule.data[0];

            if (
              !checkData.hasOwnProperty('Control #') ||
              !checkData.hasOwnProperty('Type')
            ) {
              if (!checkData.hasOwnProperty('Control #'))
                throw Error('Schedule is missing column (Control #)');
              if (!checkData.hasOwnProperty('Type'))
                throw Error('Schedule is missing column (Type)');
            } else {
              this.currentJobMarkSchedule = markSchedule.data;
            }
          }
        });
      } catch (error) {
        if (error.message === 'Request failed with status code 404') {
          this.rootStore.notificationStore?.addNotification({
            message:
              'Error Getting Mark Schedule! Check that your mark schedule matches exactly: PCS Mark No. Parameters',

            options: { persist: true, variant: 'error' }
          });
        } else {
          this.rootStore.notificationStore?.addNotification({
            message: `Error Getting Mark Schedule: ${
              error.message ? error.message : String(error)
            } `,

            options: { persist: true, variant: 'error' }
          });
        }
        console.error('ERROR - getMarkSchedule:', error);
      }
    }
  };

  getMaterialSchedule = async () => {
    if (this.validRevitURL) {
      try {
        const material = await axios.get(
          this.revitURL +
            'schedules/PCS Skin Parameters - Volume and Sq. Ft. (MT)'
        );

        this.debugMode && console.log('Material from Revit:', material);
        runInAction(() => {
          if (material.data && material.data.length) {
            const checkData = material.data[0];

            if (
              !checkData.hasOwnProperty('Material: Name') ||
              !checkData.hasOwnProperty('Material: Area') ||
              !checkData.hasOwnProperty('Material: Volume') ||
              !checkData.hasOwnProperty('Type')
            ) {
              if (!checkData.hasOwnProperty('Material: Name'))
                throw Error('Schedule is missing column (Material: Name)');
              if (!checkData.hasOwnProperty('Material: Area'))
                throw Error('Schedule is missing column (Material: Area)');
              if (!checkData.hasOwnProperty('Material: Volume'))
                throw Error('Schedule is missing column (Material: Volume)');
              if (!checkData.hasOwnProperty('Type'))
                throw Error('Schedule is missing column (Type)');
            } else {
              this.currentJobMaterialSchedule = material.data;
            }
          }
        });
      } catch (error) {
        if (error.message === 'Request failed with status code 404') {
          this.rootStore.notificationStore.addNotification({
            message: `Check that your material schedule matches exactly: PCS Skin Parameters - Volume and Sq. Ft. (MT)`,
            options: { persist: true, variant: 'error' }
          });
        } else {
          this.rootStore.notificationStore.addNotification({
            message: `Error Getting Material Schedule: ${
              error.message ? error.message : String(error)
            } `,

            options: { persist: true, variant: 'error' }
          });
        }

        console.error('ERROR - getMaterialSchedule:', error);
      }
    }
  };

  getSkinSchedule = async () => {
    if (this.validRevitURL) {
      try {
        const skinSchedule = await axios.get(
          this.revitURL + 'schedules/PCS Skin Parameters'
        );

        this.debugMode && console.log('Skin from Revit:', skinSchedule);

        runInAction(() => {
          if (skinSchedule.data && skinSchedule.data.length) {
            const checkData = skinSchedule.data[0];

            if (
              !checkData.hasOwnProperty('Type') ||
              !checkData.hasOwnProperty('Product Type') ||
              !checkData.hasOwnProperty('Length')
            ) {
              if (!checkData.hasOwnProperty('Type'))
                throw Error('Schedule is missing column (Type)');
              if (!checkData.hasOwnProperty('Product Type'))
                throw Error('Schedule is missing column (Product Type)');
              if (!checkData.hasOwnProperty('Length'))
                throw Error('Schedule is missing column (Length)');
            } else {
              this.currentJobSkinSchedule = skinSchedule.data;
            }
          }
        });
      } catch (error) {
        if (error.message === 'Request failed with status code 404') {
          this.rootStore.notificationStore.addNotification({
            message: `Check that your skin schedule matches exactly: PCS Skin Parameters`,
            options: { persist: true, variant: 'error' }
          });
        } else {
          this.rootStore.notificationStore.addNotification({
            message: `Error Getting Skin Schedule: ${
              error.message ? error.message : String(error)
            }`,

            options: { persist: true, variant: 'error' }
          });
        }
        console.error('ERROR - getSkinSchedule:', error);
      }
    }
  };

  stopServer = async () => {
    if (isDev) return;
    try {
      this.debugMode && console.log('Trying to kill server');
      await axios.get(this.revitURL + 'stop-server');
    } catch (error) {
      console.warn(error);
    }
  };

  refresh = async () => {
    let status;
    const { jobNumber, revitURL, setStatus } = this.rootStore.uiStore;
    try {
      this.setIsRefreshing(true);
      runInAction(() => {
        this.jobNumber = jobNumber;
        this.revitURL = revitURL;
      });
      status = await this.getStatus();

      if (status > 2017) {
        setStatus(status);

        const pluginVersion = await axios.get(this.revitURL + 'version');
        if (!versionCheck(pluginVersion.data, '22.8.0'))
          throw Error(
            `Data Upload v${pluginVersion.data} is out of date. Update from the Compass Plugin Manager to continue`
          );
        if (this.rootStore.uiStore?.jobNumber) {
          await this.getMaterialSchedule();
          await this.getMarkSchedule();
          await this.getSkinSchedule();
        }
      }
    } catch (err) {
      if (err.response && err.response.data.includes('KeyError: version')) {
        this.rootStore.notificationStore.addNotification({
          message:
            'As of 8/12/22, you must update your Data Upload plugin from the Compass Plugin Manager to continue',
          options: { persist: true, variant: 'error' }
        });
      } else {
        this.rootStore.notificationStore.addNotification({
          message: `Error Connecting to Revit: ${
            err.message ? err.message : String(err)
          } `,

          options: { persist: true, variant: 'error' }
        });
      }

      console.error('Error getting status', err);
    }
    return;
  };

  nextHardReject = () => {
    if (this.panelCount.hardReject.length > 0) {
      if (this.currentHardReject === this.panelCount.hardReject.length - 1) {
        this.currentHardReject = 0;
      } else {
        this.currentHardReject += 1;
      }
      const { rowIndex, columnIndex } =
        this.panelCount.hardReject[this.currentHardReject];
      this.setScrollToColumn(columnIndex);
      this.setScrollToRow(rowIndex + 1);
    }
  };

  nextSoftReject = () => {
    if (this.panelCount.softReject.length > 0) {
      if (this.currentSoftReject === this.panelCount.softReject.length - 1) {
        this.currentSoftReject = 0;
      } else {
        this.currentSoftReject += 1;
      }
      const { rowIndex, columnIndex } =
        this.panelCount.softReject[this.currentSoftReject];
      this.setScrollToColumn(columnIndex);
      this.setScrollToRow(rowIndex + 1);
    }
  };

  scrollToError = ({ columnIndex, rowIndex }) => {
    const foundErrorIndex = this.panelCount.hardReject.findIndex(
      cell => cell.rowIndex === rowIndex && columnIndex === cell.columnIndex
    );
    if (foundErrorIndex > -1) {
      this.currentHardReject = foundErrorIndex;
    }
    this.setScrollToColumn(columnIndex);
    this.setScrollToRow(rowIndex);
  };

  setScrollToColumn = value => {
    if (isNaN(value)) value = 0;
    this.scrollToColumn = Math.min(
      this.enabledColumns.length - 1,
      parseInt(value, 10)
    );
  };
  setScrollToRow = value => {
    if (isNaN(value)) value = 0;
    this.scrollToRow = Math.min(this.rowCount - 1, parseInt(value, 10));
  };

  setUpdatedPanels = updatedValues => {
    this.recentChanges = updatedValues;
  };

  get serverProgress() {
    return Number(this.progress.toFixed(0));
  }

  get validRevitURL() {
    return this.rootStore.uiStore.validRevitURL;
  }

  get mixDesigns() {
    return Array.from(
      this.currentJobMaterialSchedule.reduce(
        (jobMixDesigns, currentMaterial) => {
          if (currentMaterial['Material: Name']) {
            const materialName = currentMaterial['Material: Name'];
            const findPoundSymbol = materialName.indexOf('#');
            const mixNumber = materialName.slice(
              findPoundSymbol + 1,
              findPoundSymbol + 2
            );
            jobMixDesigns.add(mixNumber);
          }
          return jobMixDesigns;
        },
        new Set()
      )
    );
  }

  get allDataEnabledColumns() {
    return Array.from(new Set([...this.dataEnabledFormattedSkinColumns]))
      .filter(column => column !== 'controlUUID')
      .filter(column => column !== 'markUUID');
  }

  get dataEnabledFormattedSkinColumns() {
    if (
      this.formattedSkinSchedule.items &&
      this.formattedSkinSchedule.items.length
    ) {
      const currentKeys = Object.keys(this.formattedSkinSchedule.items[0]);
      const currentSkinColumns = this.formattedSkinSchedule.items
        .slice()
        .reduce((enabledMarkColumns, currentMarkData) => {
          for (const column of currentKeys) {
            const currentValue = currentMarkData[column];
            if (
              currentValue === undefined ||
              currentValue === null ||
              (currentValue && `${currentValue}` !== '0')
            ) {
              enabledMarkColumns.add(column);
            }
          }
          return enabledMarkColumns;
        }, new Set());
      return Array.from(currentSkinColumns);
    }
    return [];
  }

  get currentErrorCell() {
    if (!this.currentHardReject && !this.currentSoftReject) return null;
    if (
      this.panelCount.hardReject.length === 0 &&
      this.panelCount.softReject.length === 0
    ) {
      return null;
    }
    if (!this.currentSoftReject)
      return this.panelCount.hardReject[this.currentHardReject];
    if (!this.currentHardReject)
      return this.panelCount.hardReject[this.currentSoftReject];
    return null;
  }

  get enabledColumns() {
    return columns.slice().filter(({ variable }) => {
      if (stickyColumns.includes(variable)) return true;
      if (this.allDataEnabledColumns.includes(variable)) return true;
      return false;
    });
  }

  get selectedPanelData() {
    const enabledColumnsVariables = this.enabledColumns
      .slice()
      .map(item => item.variable);
    let hardRejectFound = [...this.panelCount.hardReject];
    let sendPanels = [];
    sendPanels = this.panelsRegex
      .slice()
      .filter((item, index) => !hardRejectFound.rowIndex !== index)
      .map((item, index) => {
        if (this.disabledRows.includes(index)) return {};
        let currentPanel = {};
        for (const enabledVar of enabledColumnsVariables) {
          if (item[enabledVar]) {
            Object.assign(currentPanel, {
              [enabledVar]:
                item[enabledVar] === 'Missing Factor' ? 0 : item[enabledVar]
            });
          } else {
            Object.assign(currentPanel, {
              [enabledVar]: null
            });
          }
        }
        if (item.rearViewIFC) {
          Object.assign(currentPanel, {
            rearViewIFC: databaseDate(item.rearViewIFC)
          });
        }

        Object.assign(currentPanel, { markUUID: item.markUUID });
        Object.assign(currentPanel, { controlUUID: item.controlUUID });
        Object.assign(currentPanel, { jobNumber: item.jobNumber });
        if (
          (currentPanel.rearViewIFC && currentPanel.rearViewIFC.length === 0) ||
          !currentPanel.rearViewIFC
        )
          Object.assign(currentPanel, { rearViewIFC: '1970-01-01' });
        return currentPanel;
      });

    return sendPanels;
  }

  get panelCount() {
    const assemblyPanelCount = this.panelsRegex
      .slice()
      .filter(item => item.controlNumber.slice(-2) === '.1').length;
    const regPanelCount = this.panelsRegex
      .slice()
      .filter(item => item.controlNumber.slice(-2) !== '.1').length;
    let hardReject = [];
    let softReject = [];
    const regexValidationFailCount = this.panelsRegex
      .slice()
      .reduce((failCount, panel, currentIndex) => {
        const enabledColumns = Object.values(this.enabledColumns.slice()).map(
          item => item.variable
        );
        const findFails = Object.entries(panel)
          .filter(([variable, _]) => variable.includes('Regex'))
          .filter(([_, value]) => !value)

          .filter(([variable, _]) =>
            enabledColumns.includes(variable.replace('Regex', ''))
          )
          .filter(([variable, _]) => !this.disabledRows.includes(currentIndex))
          .map(([variable, value]) => {
            const panelVariable = variable.slice(0, variable.length - 5);
            const columnLocation = enabledColumns.findIndex(
              item => item === variable.replace('Regex', '')
            );
            if (panel[panelVariable] === 'Missing Factor') {
              const productType = panel['productType'];
              if (productType === 'C') {
                if (panelVariable === 'looseBarCount') {
                  softReject.push({
                    rowIndex: currentIndex,
                    columnIndex: columnLocation
                  });
                }
              }
              if (productType === 'G' || productType === 'T') {
                if (panelVariable === 'totalWeldInches') {
                  softReject.push({
                    rowIndex: currentIndex,
                    columnIndex: columnLocation
                  });
                }
              }
            } else {
              hardReject.push({
                rowIndex: currentIndex,
                columnIndex: columnLocation
              });
            }
            return variable;
          });
        return failCount + findFails.length;
      }, 0);
    const { dupes } = this.panelsRegex
      .slice()
      .map(p => p.controlNumber)
      .sort()
      .reduce(
        (a, b, currentIndex) => {
          if (a.loop.indexOf(parseFloat(b)) > -1) {
            a.dupes.push(b);
            hardReject.push({
              rowIndex: currentIndex,
              columnIndex: 0
            });
          } else {
            a.loop.push(parseFloat(b));
          }
          return a;
        },
        { loop: [], dupes: [] }
      );
    const validationFailCount = regexValidationFailCount + dupes.length || 0;

    return {
      regPanelCount,
      assemblyPanelCount,
      validationFailCount,
      hardReject,
      softReject,
      dupes
    };
  }

  get rowCount() {
    const { regPanelCount, assemblyPanelCount } = this.panelCount;
    return regPanelCount + assemblyPanelCount;
  }

  get isReady() {
    return this.revitYear !== '';
  }

  get currentJob() {
    return this.rootStore.uiStore.currentJob;
  }

  get panelFactors() {
    if (!this.rootStore.panelRevitFactorsStore?.entities) {
      return [];
    }
    return this.rootStore.panelRevitFactorsStore.entities
      .slice()
      .filter(
        skinFactor =>
          parseInt(skinFactor.jobNumber) ===
          parseInt(this.rootStore.uiStore.jobNumber)
      );
  }

  get hardwareOrders() {
    if (
      !this.formattedSkinSchedule ||
      !this.formattedSkinSchedule.items ||
      !this.formattedSkinSchedule.items.length
    )
      return [];
    return Array.from(
      new Set(
        this.formattedSkinSchedule.items
          .slice()
          .filter(x => x.hardwareOrderNumber)
          .map(x => x.hardwareOrderNumber)
      )
    );
  }

  get newHardwareOrders() {
    if (
      !this.selectedPanelData?.length ||
      !Object.keys(this.panelsWithUpdates)?.length
    )
      return [];

    let foundNewHardwareOrders = [];
    const { newPanels, panelsWithUpdates } = this.panelsWithUpdates;

    let allHardwareOrders = [...newPanels, ...panelsWithUpdates];

    let uniqueHardwareOrders = uniqueArray(
      allHardwareOrders
        .slice()
        .filter(item => item.hardwareOrderNumber)
        .map(item => item.hardwareOrderNumber)
    );

    if (uniqueHardwareOrders.length) {
      if (!this.serverHardwareOrderNumbers.length) {
        return uniqueHardwareOrders.slice().map(order => {
          let parsedOrder = order.split(/[- ()]/).filter(part => part !== '');

          let hardwareType = parsedOrder?.[0] || '';
          let hardwareOrderNumber = parsedOrder?.[1] || '';
          let description = parsedOrder?.[2] || '';

          return {
            hardwareType: hardwareType,
            orderNumber: hardwareOrderNumber,
            description: description
          };
        });
      }

      foundNewHardwareOrders = uniqueHardwareOrders
        .slice()
        .filter(order => {
          const foundDBOrder = this.serverHardwareOrderNumbers.find(dbOrder => {
            let parsedOrder = order.split(/[- ()]/).filter(part => part !== '');

            let hardwareType = parsedOrder?.[0] || '';
            let hardwareOrderNumber = parsedOrder?.[1] || '';
            let description = parsedOrder?.[2] || '';

            return (
              dbOrder?.hardwareType === hardwareType &&
              dbOrder?.orderNumber === hardwareOrderNumber &&
              dbOrder?.description === description
            );
          });

          if (foundDBOrder) return false;
          return true;
        })
        .map(order => {
          let parsedOrder = order.split(/[- ()]/).filter(part => part !== '');

          let hardwareType = parsedOrder?.[0] || '';
          let hardwareOrderNumber = parsedOrder?.[1] || '';
          let description = parsedOrder?.[2] || '';

          return {
            hardwareType: hardwareType,
            orderNumber: hardwareOrderNumber,
            description: description
          };
        });
    }

    return foundNewHardwareOrders;
  }

  get totalIfcUpdates() {
    const { newIfcs, existingIfcs } = this.panelsWithUpdates;
    return [...newIfcs, ...existingIfcs];
  }

  get totalPanelUpdates() {
    const { newPanels, panelsWithUpdates } = this.panelsWithUpdates;

    return [...newPanels, ...panelsWithUpdates];
  }

  get panelsWithUpdates() {
    if (
      !this.compareToDB ||
      !this.compareToDB.length ||
      !this.selectedPanelData ||
      !this.selectedPanelData.length
    )
      return {
        newPanels: [],
        panelsWithUpdates: [],
        newIfcs: [],
        existingIfcs: []
      };
    let newPanels = [];
    let panelsWithUpdates = [];
    let newIfcs = [];
    let existingIfcs = [];
    this.compareToDB.slice(1).map((panel, existingPanelIndex) => {
      if (Object.keys(panel).length) {
        const foundPanelWithUpdateIndex = this.selectedPanelData
          .slice()
          .findIndex((currentPanel, currentPanelIndex) => {
            return currentPanelIndex === existingPanelIndex;
          });
        if (foundPanelWithUpdateIndex !== -1) {
          const selectedPanel = this.selectedPanelData[existingPanelIndex];
          if (Object.keys(selectedPanel).length) {
            if (
              panel.hasOwnProperty('db-controlUUID') &&
              panel['db-controlUUID'] === 'None'
            ) {
              const panelWithoutIfc = Object.keys(selectedPanel)
                .filter(key => key !== 'rearViewIFC')
                .reduce(
                  (curr, key) =>
                    Object.assign(curr, { [key]: selectedPanel[key] }),
                  {}
                );
              newPanels.push({ ...panelWithoutIfc });
              if (panel.hasOwnProperty('db-rearViewIFC')) {
                const ifcDataFromDB = this.serverIFCStatuses.find(
                  ifc =>
                    isSameJobNumber(
                      this.rootStore.uiStore.jobNumber,
                      ifc.jobNumber
                    ) && ifc.markNumber === selectedPanel.markNumber
                );
                if (ifcDataFromDB) {
                  const alreadyAdded = existingIfcs.find(
                    ifc => ifc.id === ifcDataFromDB.id
                  );
                  if (!alreadyAdded) {
                    existingIfcs.push({
                      id: ifcDataFromDB.id,
                      panelModeled: ifcDataFromDB.panelModeled
                        ? ifcDataFromDB.panelModeled
                        : selectedPanel.rearViewIFC !== '1970-01-01',
                      panelDrawn: ifcDataFromDB.panelDrawn
                        ? ifcDataFromDB.panelDrawn
                        : selectedPanel.rearViewIFC !== '1970-01-01',
                      panelChecked: ifcDataFromDB.panelChecked
                        ? ifcDataFromDB.panelChecked
                        : selectedPanel.rearViewIFC !== '1970-01-01',
                      rearViewIFC: selectedPanel.rearViewIFC
                    });
                  }
                } else {
                  const alreadyAdded = newIfcs.find(
                    ifc => ifc.markNumber === selectedPanel.markNumber
                  );
                  if (!alreadyAdded) {
                    newIfcs.push({
                      jobNumber: this.rootStore.uiStore.currentJob.jobNumber,
                      markNumber: selectedPanel.markNumber,
                      panelModeled: selectedPanel.rearViewIFC !== '1970-01-01',
                      panelDrawn: selectedPanel.rearViewIFC !== '1970-01-01',
                      panelChecked: selectedPanel.rearViewIFC !== '1970-01-01',
                      rearViewIFC: selectedPanel.rearViewIFC
                    });
                  }
                }
              }
            } else {
              const panelFromDB = this.serverPanels
                .slice()
                .find(
                  dbPanel => dbPanel.controlUUID === selectedPanel.controlUUID
                );

              const { id } = panelFromDB;
              let newData = {};
              Object.keys(selectedPanel).map(key => {
                if (
                  Object.keys(panel).includes(`db-${key}`) &&
                  key !== 'rearViewIFC'
                ) {
                  Object.assign(newData, {
                    [`${key}`]: selectedPanel[key]
                  });
                }
              });

              if (Object.keys(newData) && Object.keys(newData).length)
                panelsWithUpdates.push({ id: id, ...newData });
              if (panel.hasOwnProperty('db-rearViewIFC')) {
                const ifcDataFromDB = this.serverIFCStatuses.find(
                  ifc =>
                    isSameJobNumber(
                      this.rootStore.uiStore.jobNumber,
                      ifc.jobNumber
                    ) && ifc.markNumber === selectedPanel.markNumber
                );
                if (ifcDataFromDB) {
                  const alreadyAdded = existingIfcs.find(
                    ifc => ifc.id === ifcDataFromDB.id
                  );
                  if (!alreadyAdded) {
                    existingIfcs.push({
                      id: ifcDataFromDB.id,
                      panelModeled: ifcDataFromDB.panelModeled
                        ? ifcDataFromDB.panelModeled
                        : selectedPanel.rearViewIFC !== '1970-01-01',
                      panelDrawn: ifcDataFromDB.panelDrawn
                        ? ifcDataFromDB.panelDrawn
                        : selectedPanel.rearViewIFC !== '1970-01-01',
                      panelChecked: ifcDataFromDB.panelChecked
                        ? ifcDataFromDB.panelChecked
                        : selectedPanel.rearViewIFC !== '1970-01-01',
                      rearViewIFC: selectedPanel.rearViewIFC
                    });
                  }
                } else {
                  const alreadyAdded = newIfcs.find(
                    ifc => ifc.markNumber === selectedPanel.markNumber
                  );
                  if (!alreadyAdded) {
                    newIfcs.push({
                      jobNumber: this.rootStore.uiStore.currentJob.jobNumber,
                      markNumber: selectedPanel.markNumber,
                      panelModeled: selectedPanel.rearViewIFC !== '1970-01-01',
                      panelDrawn: selectedPanel.rearViewIFC !== '1970-01-01',
                      panelChecked: selectedPanel.rearViewIFC !== '1970-01-01',
                      rearViewIFC: selectedPanel.rearViewIFC
                    });
                  }
                }
              }
            }
          }
        }
      }
    });

    return {
      newPanels: newPanels,
      panelsWithUpdates: panelsWithUpdates,
      newIfcs: newIfcs,
      existingIfcs: existingIfcs
    };
  }

  get serverPanelsWithIfcs() {
    return this.serverPanels.slice().map(panel => {
      const foundIFCData = this.serverIFCStatuses.find(
        ifcPanel =>
          isSameJobNumber(ifcPanel.jobNumber, panel.jobNumber) &&
          ifcPanel.markNumber === panel.markNumber
      );
      if (foundIFCData) {
        return { ...panel, rearViewIFC: foundIFCData.rearViewIFC };
      }
      return { ...panel, rearViewIFC: '1970-01-01' };
    });
  }

  get compareToDB() {
    const diffComp = this.panels.map((revitPanel, index) => {
      if (index === 0) return revitPanel;
      const foundPanel = this.serverPanelsWithIfcs
        .slice()
        .find(p => p.controlUUID === revitPanel.controlUUID);
      if (foundPanel) {
        const diff = Object.entries(revitPanel)
          .filter(([revitPanelKey, revitPanelValue]) => {
            if (revitPanelValue === 'Missing Factor') return false;

            const foundColumnKey = this.columnKeys
              .slice()
              .find(column => column === revitPanelKey);

            if (!foundColumnKey) return false;

            const currentDBValue = foundPanel[revitPanelKey];

            if (currentDBValue === null && revitPanelValue) return true;
            if (
              (!String(revitPanelValue) ||
                revitPanelValue === null ||
                revitPanelValue === undefined) &&
              currentDBValue
            )
              return true;

            if (
              revitPanelValue === '1970-01-01' &&
              currentDBValue !== '1970-01-01'
            )
              return true;

            if (currentDBValue !== undefined && revitPanelValue) {
              const isPanelValueNumber = !isNaN(revitPanelValue);
              const isDBValueNumber = !isNaN(currentDBValue);
              if (isPanelValueNumber && isDBValueNumber) {
                if (!Number.isInteger(revitPanelValue)) {
                  if (
                    parseFloat(currentDBValue) !== parseFloat(revitPanelValue)
                  )
                    return true;
                } else if (Number.isInteger(revitPanelValue)) {
                  if (String(currentDBValue) !== String(revitPanelValue))
                    return true;
                }
              }
              if (String(currentDBValue) !== String(revitPanelValue))
                return true;
            }
            return false;
          })
          .map(([revitPanelKey, revitPanelValue]) => {
            return {
              [`db-${revitPanelKey}`]: foundPanel[revitPanelKey]
            };
          })
          .reduce((r, c) => Object.assign(r, c), {});

        return { ...diff };
      } else {
        const foundPanel = this.selectedPanelData.find(
          p => p.controlNumber === revitPanel.controlNumber
        );
        if (foundPanel) {
          const diff = Object.entries(foundPanel)

            .filter(([revitPanelKey, revitPanelValue]) => revitPanelValue)
            .filter(([revitPanelKey, revitPanelValue]) => {
              if (revitPanelKey !== 'rearViewIFC') return true;

              const currentDBIfcData = this.serverIFCStatuses.find(
                ifc => ifc.markNumber === revitPanel.markNumber
              );

              if (currentDBIfcData) {
                if (revitPanelValue !== currentDBIfcData.rearViewIFC) {
                  return true;
                }
              }
              if (!currentDBIfcData) {
                return true;
              }

              return false;
            })
            .map(([revitPanelKey, revitPanelValue]) => {
              return {
                [`db-${revitPanelKey}`]: 'None'
              };
            })
            .reduce((r, c) => Object.assign(r, c), {});

          return { ...diff };
        }
        return {};
      }
    });
    return diffComp;
  }
  get columnKeys() {
    return this.enabledColumns.map(c => c.variable);
  }

  get formattedMarkSchedule() {
    if (!this.currentJobMarkSchedule.length) return [];
    let joined = this.currentJobMarkSchedule.map(panel => {
      const rearViewIFC = panel['RV IFC Date']
        ? databaseDate(panel['RV IFC Date'])
        : '1970-01-01';
      let hardwareOrderNumber = '';
      if ('Hardware Order' in panel) {
        hardwareOrderNumber = panel['Hardware Order'];
      } else if ('Hardware Order #' in panel) {
        hardwareOrderNumber = panel['Hardware Order #'];
      }

      const markNumberNormalized = panel['Type'].replaceAll(' ', '');
      const controlNumberNormalized = panel['Control #']
        .replaceAll('-', '.')
        .replaceAll(' ', '');
      let markObj = {
        controlNumber: controlNumberNormalized || 'Missing',
        craneSetupNumber: panel['Crane Set-Up'],
        installationSequence:
          panel['Installation Sequence'] || panel['Erection Sequence'],
        levelingBoltDescription: panel['Leveling Bolt Description'] || '',
        looseBarCount: panel['Loose Bar Count'] || 0,
        individualPanelWeight: panel['Panel Weight'] || 0,
        rearViewIFC: rearViewIFC,
        totalWeldInches: panel['Total Weld Inches'] || 0,
        markNumber: markNumberNormalized || 'Missing',
        jobNumber: this.jobNumber,
        markUUID: `${this.jobNumber}${markNumberNormalized}`,
        controlUUID: `${this.jobNumber}${controlNumberNormalized}`,
        hardwareOrderNumber: hardwareOrderNumber
        // elevation from revit
      };

      return markObj;
    });

    const panelAssemblies = joined.map(panel => {
      const assemblies = joined.filter(item =>
        item.controlNumber.includes(panel.controlNumber)
      );
      panel.assembledPanelWeight = assemblies.reduce(
        (acc, curr) =>
          parseInt(acc) + parseInt(curr.individualPanelWeight) || 0,
        0
      );
      if (panel.controlNumber.includes('.')) panel.assembledPanelWeight = 0;
      return panel;
    });
    return panelAssemblies;
  }

  get formattedMaterialSchedule() {
    let materialErrors = { missingBase: [], exceedTotalMixCubicYd: [] };
    if (
      !this.formattedMarkSchedule.length ||
      !this.currentJobMaterialSchedule.length
    )
      return [];
    let joined = this.formattedMarkSchedule
      .slice()
      .filter(markSkinPanel => {
        const foundPeriod = markSkinPanel.markNumber.indexOf('.');
        return foundPeriod !== -1;
      })
      .map(markSkinPanel => {
        const foundPeriod = markSkinPanel.markNumber.indexOf('.');
        const markType =
          markSkinPanel.markNumber.slice(0, foundPeriod + 3) +
          markSkinPanel.markNumber.slice(foundPeriod + 4);

        const panelMaterials = this.currentJobMaterialSchedule.filter(
          item => item['Type'] === markType
        );

        const panelBases = panelMaterials.slice().filter(item =>
          item['Material: Name']
            .toLowerCase()
            .replace(/[^\w\s!?]/g, '')
            .includes('base')
        );
        const panelMixes = panelMaterials.slice().filter(
          item =>
            !item['Material: Name']
              .toLowerCase()
              .replace(/[^\w\s!?]/g, '')
              .includes('base')
        );
        const panelExposed = panelMaterials.filter(item =>
          item['Material: Name'].includes('Exposed')
        );
        const panelUnexposed = panelMaterials.filter(item =>
          item['Material: Name'].includes('Unexposed')
        );
        const panelSprayFoam = panelMaterials.filter(item =>
          item['Material: Name'].includes('Spray Foam')
        );
        let additionalExposed = 0;
        if (panelExposed.length > 0)
          additionalExposed = panelExposed.reduce(
            (acc, current) =>
              acc + parseInt(current['Material: Area'].replace(/[^0-9.]/g, '')),
            0
          );
        let additionalUnexposed = 0;
        if (panelUnexposed.length > 0)
          additionalUnexposed = panelUnexposed.reduce(
            (acc, current) =>
              acc + parseInt(current['Material: Area'].replace(/[^0-9.]/g, '')),
            0
          );
        let sprayOnFoamSqFt = 0;
        if (panelSprayFoam.length > 0)
          sprayOnFoamSqFt = panelSprayFoam.reduce(
            (acc, current) =>
              acc + parseInt(current['Material: Area'].replace(/[^0-9.]/g, '')),
            0
          );

        for (const mixNumber of [1, 2, 3, 4]) {
          const checkMix = panelMixes.find(item =>
            item['Material: Name']
              .toLowerCase()
              .replace(/\s/g, '')
              .includes(`mix#${mixNumber}`)
          );
          if (!checkMix) {
            Object.assign(markSkinPanel, {
              [`mixDesign${mixNumber}`]: '',
              [`mix${mixNumber}SqFt`]: 0,
              [`mix${mixNumber}Volume`]: 0
            });
          } else {
            const panelBase = panelBases.find(item =>
              item['Material: Name']
                .toLowerCase()
                .replace(/\s/g, '')
                .includes(`mix#${mixNumber}`)
            );
            if (!panelBase) {
              materialErrors.missingBase = [
                ...materialErrors.missingBase,
                markType
              ];
            }

            if (!checkMix || !checkMix['Material: Area']) {
              console.log('checkMix', toJS(checkMix));
            } else if (!panelBase || !panelBase['Material: Volume']) {
              Object.assign(markSkinPanel, {
                [`mixDesign${mixNumber}`]: '',
                [`mix${mixNumber}SqFt`]: 0,
                [`mix${mixNumber}Volume`]: 0
              });
            } else {
              Object.assign(markSkinPanel, {
                [`mixDesign${mixNumber}`]: parseMix(checkMix['Material: Name']),
                [`mix${mixNumber}SqFt`]:
                  parseInt(
                    checkMix['Material: Area'].replace(/[^0-9.]/g, '')
                  ) || 0,
                [`mix${mixNumber}Volume`]: parseFloat(
                  panelBase['Material: Volume'] || 0
                )
              });
            }
          }
        }
        const totalSqFt =
          markSkinPanel.mix1SqFt +
          markSkinPanel.mix2SqFt +
          markSkinPanel.mix3SqFt +
          markSkinPanel.mix4SqFt;
        const totalMixCubicYd =
          markSkinPanel.mix1Volume +
          markSkinPanel.mix2Volume +
          markSkinPanel.mix3Volume +
          markSkinPanel.mix4Volume;

        const roundTotalMixCubicYd = totalMixCubicYd.toFixed(2);
        if (roundTotalMixCubicYd > 20) {
          materialErrors.exceedTotalMixCubicYd = [
            ...materialErrors.exceedTotalMixCubicYd,
            markType
          ];
          return Object.assign(markSkinPanel, {
            totalSqFt: totalSqFt || 0,
            totalMixCubicYd: parseFloat(roundTotalMixCubicYd),
            sprayOnFoamSqFt: String(sprayOnFoamSqFt),
            additionalExposedSqFt: String(additionalExposed),
            additionalUnexposedSqFt: String(additionalUnexposed)
          });
        } else {
          if (
            !markSkinPanel.looseBarCount ||
            markSkinPanel.looseBarCount === '0'
          ) {
            if (this.panelFactors && this.panelFactors.length) {
              const foundFactor = this.panelFactors.find(
                skinFactor =>
                  markSkinPanel.markNumber.includes(skinFactor.skin) &&
                  skinFactor.factorType === 'looseBarFactor'
              );
              if (foundFactor) {
                const parseLooseBar = parseFloat(foundFactor.factor);
                const parseSqFt = parseInt(totalSqFt);
                const looseBarCount = Math.floor(parseLooseBar * parseSqFt);
                Object.assign(markSkinPanel, {
                  looseBarCount: looseBarCount,
                  usedLooseBarFactor: true
                });
                // this.setLooseBarFactorUsed(markSkinPanel.controlNumber);
              } else {
                Object.assign(markSkinPanel, {
                  looseBarCount: 'Missing Factor'
                });
              }
            } else {
              Object.assign(markSkinPanel, {
                looseBarCount: 'Missing Factor'
              });
            }
          }

          if (
            !markSkinPanel.totalWeldInches ||
            markSkinPanel.totalWeldInches === '0'
          ) {
            if (this.panelFactors && this.panelFactors.length) {
              const foundFactor = this.panelFactors.find(
                skinFactor =>
                  markSkinPanel.markNumber.includes(skinFactor.skin) &&
                  skinFactor.factorType === 'weldInchFactor'
              );
              if (foundFactor) {
                const parseWeld = parseFloat(foundFactor.factor);
                const parseSqFt = parseInt(totalSqFt);
                const totalWeldInches = Math.floor(parseWeld * parseSqFt);
                Object.assign(markSkinPanel, {
                  totalWeldInches: totalWeldInches,
                  usedWeldInchFactor: true
                });
                // this.setWeldInchFactorUsed(markSkinPanel.controlNumber);
              } else {
                Object.assign(markSkinPanel, {
                  totalWeldInches: 'Missing Factor'
                });
              }
            } else {
              Object.assign(markSkinPanel, {
                totalWeldInches: 'Missing Factor'
              });
            }
          }
          return Object.assign(markSkinPanel, {
            totalSqFt: totalSqFt || 0,
            totalMixCubicYd: parseFloat(roundTotalMixCubicYd),
            sprayOnFoamSqFt: String(sprayOnFoamSqFt),
            additionalExposedSqFt: String(additionalExposed),
            additionalUnexposedSqFt: String(additionalUnexposed)
          });
        }
      });

    return {
      items: joined,
      errors: {
        missingBase: uniqueArray(materialErrors.missingBase).sort(),
        exceedTotalMixCubicYd: uniqueArray(
          materialErrors.exceedTotalMixCubicYd
        ).sort()
      }
    };
  }

  get formattedSkinSchedule() {
    let skinScheduleErrors = { missingSkin: [], missingProdType: [] };
    if (
      !this.formattedMarkSchedule ||
      !this.formattedMaterialSchedule ||
      !this.formattedMaterialSchedule.items ||
      !this.formattedMaterialSchedule.items.length ||
      !this.currentJobSkinSchedule.length ||
      this.formattedMaterialSchedule?.errors?.missingBase?.length ||
      this.formattedMaterialSchedule?.errors?.exceedTotalMixCubicYd?.length
    )
      return [];

    let markSkin = this.formattedMaterialSchedule.items
      .slice()
      .filter(markPanel => {
        const foundPeriod = markPanel.markNumber.indexOf('.');
        return foundPeriod !== -1;
      })
      .map(markPanel => {
        const foundPeriod = markPanel.markNumber.indexOf('.');
        const markType =
          markPanel.markNumber.slice(0, foundPeriod + 3) +
          markPanel.markNumber.slice(foundPeriod + 4);
        let panelSkin = this.currentJobSkinSchedule.find(
          item => item['Type'] === markType
        );

        if (panelSkin) {
          const baseProductType = isNaN(parseInt(panelSkin['Product Type']))
            ? panelSkin['Product Type']
            : parseInt(panelSkin['Product Type']);

          const netExposedSqFtCalc =
            markPanel.totalSqFt +
              markPanel.additionalExposedSqFt -
              markPanel.additionalUnexposed -
              panelSkin.unexposedVeneerSqFt || 0;

          if (
            markPanel.individualPanelWeight === 0 ||
            !markPanel.individualPanelWeight
          ) {
            const skinKeys = Object.keys(panelSkin);
            const hasWeight = skinKeys.includes('Panel Weight');
            if (hasWeight) {
              const checkSkinWeight = panelSkin['Panel Weight'];
              if (!isNaN(parseInt(checkSkinWeight)))
                Object.assign(markPanel, {
                  individualPanelWeight: parseInt(checkSkinWeight)
                });
            }
          }

          if (
            !markPanel.looseBarCount ||
            parseInt(markPanel.looseBarCount) === 0
          ) {
            if (this.panelFactors && this.panelFactors.length) {
              const foundFactor = this.panelFactors.find(
                skinFactor =>
                  markPanel.markNumber.includes(skinFactor.skin) &&
                  skinFactor.factorType === 'looseBarFactor'
              );
              if (foundFactor) {
                const parseLooseBar = parseFloat(foundFactor.factor);
                const parseSqFt = parseInt(markPanel.totalSqFt);
                const looseBarCount = Math.floor(parseLooseBar * parseSqFt);

                Object.assign(markPanel, {
                  looseBarCount: looseBarCount,
                  usedLooseBarFactor: true
                });
                // this.setLooseBarFactorUsed(markPanel.controlNumber);
              } else {
                Object.assign(markPanel, {
                  looseBarCount: 'Missing Factor'
                });
              }
            }
          }

          if (
            !markPanel.totalWeldInches ||
            parseInt(markPanel.totalWeldInches) === 0
          ) {
            if (this.panelFactors && this.panelFactors.length) {
              const foundFactor = this.panelFactors.find(
                skinFactor =>
                  markPanel.markNumber.includes(skinFactor.skin) &&
                  skinFactor.factorType === 'weldInchFactor'
              );
              if (foundFactor) {
                const parseWeld = parseFloat(foundFactor.factor);
                const parseSqFt = parseInt(markPanel.totalSqFt);
                const totalWeldInches = Math.floor(parseWeld * parseSqFt);
                Object.assign(markPanel, {
                  totalWeldInches: totalWeldInches,
                  usedWeldInchFactor: true
                });
                // this.setWeldInchFactorUsed(markPanel.controlNumber);
              } else {
                Object.assign(markPanel, {
                  totalWeldInches: 'Missing Factor'
                });
              }
            }
          }
          const currentProductType = productTypeConversion[baseProductType];
          if (
            !currentProductType ||
            currentProductType === undefined ||
            currentProductType === null
          ) {
            skinScheduleErrors.missingProdType.push(panelSkin);
          } else {
            if (
              ['T', 'G'].includes(currentProductType) &&
              markPanel.looseBarCount
            ) {
              Object.assign(markPanel, {
                looseBarCount: null
              });
            }

            return Object.assign(markPanel, {
              brickCount: panelSkin['Brick Count'],
              caulkingLinearFt: panelSkin['Caulking Linear Ft'],
              naturalStoneCount: panelSkin['Natural Stone Count'],
              productType: currentProductType,
              terraCottaCount: panelSkin['Terra Cotta Count'],
              panelLength: panelSkin['Length'],
              tileCount: panelSkin['Tile Count'],
              veneerSqFt: panelSkin['Veneer Sq Ft']
                .replace(' SF', '')
                .replaceAll(' ', ''),
              netExposedSqFt: String(netExposedSqFtCalc)
            });
          }
        } else {
          skinScheduleErrors.missingSkin.push(markPanel);
        }
        return markPanel;
      });
    return {
      errors: {
        missingSkins: uniqueArray(skinScheduleErrors.missingSkin),
        missingProdTypes: uniqueArray(skinScheduleErrors.missingProdType)
      },
      items: markSkin
    };
  }

  get panelsRegex() {
    if (
      !this.formattedSkinSchedule.items ||
      !this.formattedSkinSchedule.items.length ||
      this.formattedSkinSchedule.errors.missingSkins.length > 0 ||
      this.formattedSkinSchedule.errors.missingProdTypes.length > 0
    )
      return [];
    return this.formattedSkinSchedule.items
      .slice()
      .sort((a, b) => a.controlNumber - b.controlNumber)
      .map(panel => {
        for (const [variable, value] of Object.entries(panel)) {
          // if (variable.includes('usedJob')) return panel;
          if (!String(value) || value === undefined || value === null)
            panel[`${variable}Regex`] = true;
          if (value) {
            const validation = panelSchema[variable];
            if (!validation || !validation.regex) {
              // console.warn('missing', variable, validation);
            } else {
              const checkRegex = !!String(value).match(validation.regex);
              panel[`${variable}Regex`] = checkRegex;
              if (
                validation.stringLength &&
                String(value).length > validation.stringLength
              )
                panel[`${variable}Regex`] = false;
            }
          }
        }
        return panel;
      });
  }

  get panels() {
    if (
      !this.formattedSkinSchedule.items ||
      !this.formattedSkinSchedule.items.length ||
      this.formattedSkinSchedule.errors.missingSkins.length > 0 ||
      this.formattedSkinSchedule.errors.missingProdTypes.length > 0
    )
      return [{ title: 'Header' }];

    return [
      { title: 'Header' },
      ...this.formattedSkinSchedule.items
        .slice()
        .sort((a, b) => a.controlNumber - b.controlNumber)
    ];
  }

  get serverPanels() {
    if (!this.rootStore.panelRevitStore?.entities) return [];
    return this.rootStore.panelRevitStore.entities
      .slice()
      .filter(panel =>
        isSameJobNumber(this.rootStore.uiStore.jobNumber, panel.jobNumber)
      );
  }

  get serverIFCStatuses() {
    if (!this.rootStore.ifcStatusStore?.entities) return [];
    return this.rootStore.ifcStatusStore.entities
      .slice()
      .filter(panel =>
        isSameJobNumber(this.rootStore.uiStore.jobNumber, panel.jobNumber)
      );
  }
  get serverHardwareOrderNumbers() {
    if (
      !this.rootStore.hardwareOrdersStore?.entities ||
      !this.rootStore.hardwareOrdersStore?.entities?.length
    )
      return [];
    return this.rootStore.hardwareOrdersStore.entities
      .slice()
      .filter(hw =>
        isSameJobNumber(this.rootStore.uiStore.jobNumber, hw.jobNumber)
      );
  }

  get autoColumns() {
    return [];
  }
}

export default PCSUploadStore;
