const columns = [
  { variable: 'controlNumber', title: 'Control #' },
  { variable: 'orderNumber', title: 'Hardware Order' },
  { variable: 'markNumber', title: 'Mark #' },
  { variable: 'productType', title: 'Type' },
  { variable: 'mixDesign1', title: 'Mix 1' },
  { variable: 'mix1Volume', title: 'Mix 1 Vol' },
  { variable: 'mix1SqFt', title: 'Mix 1 SqFt' },
  { variable: 'mixDesign2', title: 'Mix 2' },
  { variable: 'mix2Volume', title: 'Mix 2 Vol' },
  { variable: 'mix2SqFt', title: 'Mix 2 SqFt' },
  { variable: 'mixDesign3', title: 'Mix 3' },
  { variable: 'mix3Volume', title: 'Mix 3 Vol' },
  { variable: 'mix3SqFt', title: 'Mix 3 SqFt' },
  { variable: 'mixDesign4', title: 'Mix 4' },
  { variable: 'mix4Volume', title: 'Mix 4 Vol' },
  { variable: 'mix4SqFt', title: 'Mix 4 SqFt' },
  { variable: 'totalMixCubicYd', title: 'Total Mix CuYd' },
  { variable: 'totalSqFt', title: 'Total SqFt' },
  { variable: 'brickCount', title: 'Brick Count' },
  { variable: 'naturalStoneCount', title: 'NS Count' },
  { variable: 'terraCottaCount', title: 'TC Count' },
  { variable: 'tileCount', title: 'Tile Count' },
  { variable: 'veneerSqFt', title: 'Veneer SqFt' },
  { variable: 'installationSequence', title: 'Install Seq' },
  { variable: 'craneSetupNumber', title: 'Crane Setup' },
  { variable: 'levelingBoltDescription', title: 'Leveling Bolt' },
  { variable: 'rearViewIFC', title: 'IFC Date' },
  { variable: 'looseBarCount', title: 'Loose Bar #' },
  { variable: 'totalWeldInches', title: 'Total Weld Inches' },
  { variable: 'caulkingLinearFt', title: 'Caulking LnFt' },
  { variable: 'panelLength', title: 'Panel Length' },
  { variable: 'individualPanelWeight', title: 'Indiv Weight' },
  { variable: 'assembledPanelWeight', title: 'Assem Weight' },
  { variable: 'sprayOnFoamSqFt', title: 'Spray Foam Ins' },
  { variable: 'additionalExposedSqFt', title: `Add'l Exp SqFt` },
  {
    variable: 'additionalUnexposedSqFt',
    title: `Add'l Unexp SqFt`
  },
  { variable: 'netExposedSqFt', title: 'Net Exp SqFt' },
  { variable: 'hardwareOrderNumber', title: 'HDWR Order#' },
  { variable: 'controlUUID', title: 'ControlUUID' },
  { variable: 'markUUID', title: 'MarkUUID' }
];

const columnCount = columns.length;

const parseMix = item => {
  let foundIndex = item.indexOf('_');
  if (foundIndex === -1) {
    const foundColon = item.indexOf(':');
    if (!isNaN(foundColon)) foundIndex = foundColon + 1;
  }
  const mix = item.slice(foundIndex + 1);
  return mix;
};

export { columns, parseMix, columnCount };
