import RootStore from '@compass/store/RootStore';
import localForage from 'localforage';

import routesList from '../views/routesList';
import UIStore from './uiStore';
import PCSUploadStore from './pcsUploadStore';
import HardwareUploadStore from './hardwareUploadStore';

import packageJson from '../../package.json';

let defaultRouteLocation = 'Home';
const getMode = () => {
  if (import.meta.env.MODE === 'development') return 'development';
  if (window.location.hostname.includes('stage')) return 'stage';
  return 'prod';
};
export default new RootStore({
  appName: packageJson.name,
  appTitle: 'Revit Upload',
  storage: localForage,
  mode: getMode(),
  appVersion: packageJson.version,
  autoUpdate: true,
  enableNotificationStore: true,
  enableBatchTriggerService: true,
  syncChannels: true,
  enableAuthStore: { debugMode: false },
  apiOptions: {
    debugMode: false,
    authVersion: 2
    // uriOverride: {
    //   development: 'http://localhost:3030'
    // }
  },
  enableRouterStore: {
    routesList: routesList,
    defaultRoute: defaultRouteLocation,
    debugMode: false
  },
  serviceStores: [
    {
      name: 'app-version',
      options: { enableStorage: false }
    },
    {
      name: 'job',
      options: { enableStorage: false }
    },
    {
      name: 'panel-revit',
      options: { enableStorage: false }
    },
    { name: 'panel-revit-upload' },
    { name: 'hardware-batch-update' },
    { name: 'hardware-orders' },
    { name: 'panel-revit-factors' },
    { name: 'ifc-status' },
    { name: 'log' }
  ],
  appStores: [
    { name: 'uiStore', store: UIStore },
    { name: 'pcsUploadStore', store: PCSUploadStore },
    { name: 'hardwareUploadStore', store: HardwareUploadStore }
  ]
});
