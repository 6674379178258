import React, { lazy } from 'react';
import { LayoutRouter } from '@compass/react/LayoutProvider';
import { makeObservable, computed, reaction } from 'mobx';
import { appStyle } from '@compass/react/assets';
import { inject, observer } from 'mobx-react';
import withStyles from '@mui/styles/withStyles';
import { ErrorBoundary } from '@compass/react/ErrorBoundary';
const { dangerColor, successColor, infoColor, warningColor } = appStyle;

const style = {
  success: {
    backgroundColor: `${successColor[0]} !important`,
    zIndex: '99999'
  },
  error: { backgroundColor: `${dangerColor[0]} !important`, zIndex: '9999999' },
  warning: {
    backgroundColor: `${warningColor[0]} !important`,
    zIndex: '999999'
  },
  info: {
    backgroundColor: `${infoColor[0]} !important`,
    zIndex: '999999'
  },
  dismissButton: { textColor: 'black', zIndex: '999999' },
  close: {
    width: '11px',
    height: '11px',
    zIndex: '99999'
  }
};

//move layouts to layout folder
const LazyPCSUpload = lazy(() =>
  import('./views/PCSUpload/PCSUpload.Container')
);
const LazyHardwareUpload = lazy(() =>
  import('./views/HardwareUpload/HardwareUpload.Container')
);

const LazyAuth = lazy(() =>
  import('@compass/react/AuthLayout').then(module => ({
    default: module.AuthLayout
  }))
);

const layouts = [
  { path: '/auth', component: LazyAuth },
  { path: '/app', component: null },
  { path: '/revit', component: null },
  { path: '/pcs', component: LazyPCSUpload },
  { path: '/hardware', component: LazyHardwareUpload }
];

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    makeObservable(this, {
      watchStandardNotifications: computed
    });
    this.disposeStandardNotification = reaction(
      () => this.watchStandardNotifications,
      standardNotifications => {
        standardNotifications.slice().map(standardNotification => {
          console.log('standardNotification', toJS(standardNotification));
          return this.handleStandardNotifications(standardNotification);
        });
      },
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.disposeStandardNotification && this.disposeStandardNotification();
  }

  handleStandardNotifications = notification => {
    // console.log('handleStandardNotifications', toJS(notification));
    const { displayed, addNotification, removeStandardNotification } =
      this.props.notificationStore;
    // Do nothing if snackbar is already displayed
    if (displayed.find(n => n.key === notification.key)) {
      // console.log('already displayed');
      return;
    }
    // Display snackbar using notistack

    if (notification.standardNotificationName === 'Test') {
      // console.log('Test', toJS(notification));
      const message = 'Test';
      const options = { autoHideDuration: 3500 };
      // console.log('message', toJS(message));
      // console.log('options', toJS(options));
      addNotification({
        message: message,
        options: options
      });

      return removeStandardNotification(notification.standardNotificationName);
    }

    if (notification.standardNotificationName === 'Install iOS') {
      const {
        viewStore: { appTitle, appName }
      } = this.props;
      const message = `To install the ${
        appTitle || appName || 'App'
      }(Safari only), tap share then 'Add to Home Screen'`;
      const options = { autoHideDuration: 3000 };
      // console.log('message', toJS(message));
      // console.log('options', toJS(options));
      addNotification({
        message: message,
        options: options
      });

      return removeStandardNotification(notification.standardNotificationName);
    }
    if (notification.standardNotificationName === 'Install PWA') {
      const {
        viewStore: { appTitle, appName }
      } = this.props;
      const message = `Install ${appTitle || appName || 'App'}?`;
      const options = {
        autoHideDuration: 15000,
        action: <NotificationButtonInstallPwa />
      };
      // console.log('message', toJS(message));
      // console.log('options', toJS(options));
      addNotification({
        message: message,
        options: options
      });

      return removeStandardNotification(notification.standardNotificationName);
    }
    if (notification.standardNotificationName === 'App Update') {
      // console.log('App Update', toJS(notification));
      const message = 'Update available!';
      const options = {
        action: <NotificationButtonAppUpdate />,
        variant: 'info',
        persist: true
      };

      addNotification({
        message: message,
        options: options
      });
      return removeStandardNotification(notification.standardNotificationName);
    }
    if (notification.standardNotificationName === 'Autodesk Login') {
      const message = 'Logged Into Autodesk';
      const options = { variant: 'success', autoHideDuration: 3000 };
      // console.log('message', toJS(message));
      // console.log('options', toJS(options));
      addNotification({
        message: message,
        options: options
      });

      return removeStandardNotification(notification.standardNotificationName);
    }
    //// console.log('notification', notification);
    //// console.log('options', options);
    //// console.log('message', message);
    // return this.handleNotifications(notification, true);
  };
  get watchStandardNotifications() {
    return this.props.notificationStore?.standardNotifications?.map(noti => {
      return { ...noti };
    });
  }
  render() {
    return (
      <ErrorBoundary name="AppLayoutRouter">
        <LayoutRouter layouts={layouts} />
      </ErrorBoundary>
    );
  }
}

export default withStyles(style)(
  inject(
    'rootStore',
    'routerStore',
    'pcsUploadStore',
    'uiStore',
    'notificationStore'
  )(observer(App))
);
