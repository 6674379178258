import { makeAutoObservable, runInAction } from 'mobx';
import axios from 'axios';

class UIStore {
  appVersion = '';
  updateMessage = '';
  PCSPluginVersion = '';
  revitYear = '';
  availableServices = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false
    });

    this.rootStore.appVersionService =
      this.rootStore.app.service('app-version');

    this.services = this.rootStore.app.service('services');
  }

  dispose = () => {
    this.rootStore.appVersionService &&
      this.rootStore.appVersionService.removeListener('created');
    this.timer && clearTimeout(this.timer);
    this.authReaction && this.authReaction();
  };

  getAvailableServices = async () => {
    try {
      const { services } = await this.services.find();
      runInAction(() => {
        this.availableServices = services;
      });
    } catch (err) {
      console.log('Error getAvailableServices:', err);
    }

    return this.availableServices;
  };

  setUpdateMessage = message => {
    this.updateMessage = message;
  };

  setAppVersion = version => {
    this.rootStore.appVersion = version;
  };
  getStatus = async () => {
    if (this.validRevitURL) {
      try {
        const { data: status } = await axios.get(this.revitURL + 'status');
        this.setStatus(status);
        return status;
      } catch (error) {
        if (error.response && error.response.data)
          this.setStatus(error.response.data);
        console.warn('this.revitURL', this.revitURL);
        console.warn(error);
      }
    }
  };

  setStatus = revitYear => {
    if (revitYear) this.revitYear = revitYear;
  };

  get revitURL() {
    return `http://localhost:${this.port}/`;
  }

  get validRevitURL() {
    if (this.revitURL.includes('load')) return false;
    return this.revitURL.length > 0;
  }

  get isReady() {
    return this.revitYear !== '';
  }

  get currentJob() {
    if (!this.rootStore.jobStore || !this.rootStore.routerStore.params) {
      return {};
    }

    const foundJob = this.rootStore.jobStore?.entities
      ?.slice()
      .find(job => parseInt(job.jobNumber) === parseInt(this.jobNumber));

    return foundJob ? { ...foundJob } : {};
  }

  get jobNumber() {
    return this.rootStore.routerStore?.params?.jobNumber;
  }

  get port() {
    return this.rootStore.routerStore?.queryParams?.port;
  }

  get inBeta() {
    return this.rootStore.appVersion?.includes('beta');
  }
}

export default UIStore;
