const panelSchema = {
  floatRegex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/,
  jobNumber: {
    type: 'integer',
    regex: /^[0-9]{3}$/
  },
  controlNumber: {
    type: 'string',
    stringLength: 10,
    dashRegex: /-/g,
    regex: /^[\d]{4}(\.{0,1})([\d]{0,2})(\-{0,1})([\d]{0,2})/
  },
  markNumber: {
    type: 'string',
    stringLength: 15,
    regex: /^[A-Z]{1,4}[\d]{2}\.[\d]{2}[A-Z]{1}(\-{1}[\d]{1,3}){0,1}/
  },
  productType: {
    type: 'string',
    stringLength: 5,
    regex: /^[A-Z]{1}$/
  },
  brickCount: {
    type: 'integer',
    regex: /^[0-9]+$/
  },
  mixDesign: {
    type: 'string',
    stringLength: 20,
    regex:
      /^[0-9a-zA-Z]{1,2}-[0-9a-zA-Z]{1,2}-[0-9a-zA-Z]{1,2}(\s?\(\w{1,9}\))?/
  },
  get mixDesign1() {
    return this.mixDesign;
  },
  get mixDesign2() {
    return this.mixDesign;
  },
  get mixDesign3() {
    return this.mixDesign;
  },
  get mixDesign4() {
    return this.mixDesign;
  },
  mixVolume: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  get mix1Volume() {
    return this.mixVolume;
  },
  get mix2Volume() {
    return this.mixVolume;
  },
  get mix3Volume() {
    return this.mixVolume;
  },
  get mix4Volume() {
    return this.mixVolume;
  },
  mixSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  get mix1SqFt() {
    return this.mixSqFt;
  },
  get mix2SqFt() {
    return this.mixSqFt;
  },
  get mix3SqFt() {
    return this.mixSqFt;
  },
  get mix4SqFt() {
    return this.mixSqFt;
  },
  totalSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  totalMixCubicYd: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  naturalStoneCount: {
    type: 'integer',
    regex: /[0-9]+/
  },
  terraCottaCount: {
    type: 'integer',
    regex: /[0-9]+/
  },
  tileCount: {
    type: 'integer',
    regex: /[0-9]+/
  },
  veneerSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  unexposedVeneerSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  moldNumber: {
    type: 'string',
    stringLength: 10,
    regex: /[0-9]{1,2}\.([0-9]{1,2})((\-[0-9]){0,2}){0,1}/
  },
  installationSequence: {
    type: 'string',
    stringLength: 10,
    regex: /^[A-Za-z0-9]{1,6}$/
  },
  craneSetupNumber: {
    type: 'integer',
    regex: /[0-9]+/
  },
  levelingBoltDescription: {
    type: 'string',
    stringLength: 50,
    regex: /^\([0-9]{1,3}\)\s?[\s\S]{0,44}/
  },
  rearViewIFC: {
    type: 'string',
    stringLength: 10,
    regex: /^[\d]{4}-[\d]{2}-[\d]{2}$/
  },
  looseBarCount: {
    type: 'integer',
    regex: /[0-9]+/
  },
  totalWeldInches: {
    type: 'integer',
    regex: /[0-9]+/
  },
  caulkingLinearFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  panelLength: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  individualPanelWeight: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  assembledPanelWeight: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  controlUUID: {
    type: 'string',
    stringLength: 10,
    regex: /^[\d]{4}(\.[0-9]{1,2}(\-[0-9]){0,2}){0,1}/
  },
  markUUID: {
    type: 'string',
    stringLength: 20,
    regex: /[\d]{3}[A-Z]{1,4}[\d]{2}\.[\d]{2}[A-Z]{0,1}(\-[\d]{1,2})*/
  },
  preinstalledGlassSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  powerwashSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  additionalExposedSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  additionalUnexposedSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  netExposedSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  sprayOnFoamSqFt: {
    type: 'float',
    regex: /(^[0-9]{0,4}(\.[0-9]{0,2}$)*)/
  },
  hardwareOrderNumber: {
    type: 'string',
    regex: /[0-9]{1,4}(\s?\(\w{1}\))?/
  },
  hardwareType: {
    type: 'string',
    regex: /^(P|F|SH|FM|PM)/
  },
  description: {
    type: 'string',
    regex: /^(C|G|P|CR|E|FE|PW|FEW|PWB|T|TC|S)/
  }
};

export default panelSchema;
