import SettingsIcon from '@mui/icons-material/Settings';

const routesList = [
  {
    path: '/upload/:jobNumber', //':' define params, '?' define queryParams
    layout: '/pcs',
    name: 'PCS Upload',
    icon: SettingsIcon,
    component: () => import('./PCSUpload'),
    requiresPermission: false,
    defaultParams: { jobNumber: '' },
    channels: [
      'panel-revit/:jobNumber',
      'job/:jobNumber',
      'ifc-status/:jobNumber',
      'panel-revit-factors/:jobNumber',
      'v2/update-history',
      'hardware-orders',
      'hardware-orders/:jobNumber',
      'revit-upload-progress'
    ],
    homeShortcut: true,
    requiresAuth: true,

    beforeEnter: async ({ rootStore, route, params, queryParams }) => {
      const { find } = rootStore.panelRevitStore;
      find({ query: { jobNumber: params?.jobNumber } });
      rootStore.ifcStatusStore.find({
        query: { jobNumber: params?.jobNumber }
      });
      rootStore.jobStore.find({ query: { jobNumber: params?.jobNumber } });
      rootStore.panelRevitFactorsStore.find({
        query: { jobNumber: params?.jobNumber }
      });
      rootStore.hardwareOrdersStore.find({
        query: { jobNumber: params?.jobNumber }
      });
    },

    onEnter: async ({ rootStore }) => {
      const {
        pcsUploadStore: { refresh, setIsRefreshing }
      } = rootStore;

      await refresh().then(() => {
        setIsRefreshing(false);
      });
    }
  },
  {
    path: '/pcs-upload', //':' define params, '?' define queryParams
    layout: '/revit',
    name: 'Revit PCS Upload',
    component: undefined,
    forwardRoute: { route: 'PCSUpload' }
  },
  {
    path: '/hardware-upload/:jobNumber', //':' define params, '?' define queryParams
    layout: '/hardware',
    name: 'Hardware Upload',
    icon: SettingsIcon,
    component: () => import('./HardwareUpload'),
    defaultParams: { jobNumber: '' },
    channels: [
      'hardware-orders/:jobNumber',
      'job/:jobNumber',
      'revit-upload-progress'
    ],
    homeShortcut: true,
    requiresAuth: true,
    requiresPermission: false,

    beforeEnter: async ({ rootStore, route, params }) => {
      const { find } = rootStore.hardwareOrdersStore;

      find({ query: { jobNumber: params?.jobNumber } });
      rootStore.jobStore.find({ query: { jobNumber: params?.jobNumber } });
    },

    onEnter: async ({ rootStore }) => {
      const {
        hardwareUploadStore: { refresh, setIsRefreshing }
      } = rootStore;

      await refresh().then(() => {
        setIsRefreshing(false);
      });
    }
  },
  {
    path: '/home/:jobNumber',
    layout: '/app',
    name: 'Home',
    requiresAuth: true,
    requiresPermission: false,
    defaultParams: { jobNumber: '' },
    component: () => import('./Home'),
    homeShortcut: false,
    beforeEnter: async ({ rootStore, route, params, queryParams }) => {
      const {
        authStore: { isSignedIn },
        routerStore: { handleAuthReroute }
      } = rootStore;
      if (!isSignedIn) {
        handleAuthReroute(route, params, queryParams);
      }
    },
    onEnter: async ({ rootStore, params, queryParams }) => {
      const {
        uiStore: { jobNumber },
        routerStore: { checkReturnRoute, routes, goTo }
      } = rootStore;
      const redirectRoute = checkReturnRoute();

      if (redirectRoute) {
        let jobNumber = '';
        let port = '';
        let routeName = '';
        if (redirectRoute?.route) {
          jobNumber = redirectRoute.params?.jobNumber;
          port = redirectRoute.queryParams?.port;
          routeName = redirectRoute?.route?.key
            ? redirectRoute.route.key
            : redirectRoute?.route;
        } else {
          jobNumber = redirectRoute?.params?.jobNumber;
          port = redirectRoute?.queryParams?.port;
          routeName = redirectRoute?.route?.key
            ? redirectRoute.route.key
            : redirectRoute?.route;
        }
        if (routeName && routes[routeName] && jobNumber && port) {
          goTo('Home', { jobNumber: jobNumber }, { port: port });
        }
      }
      const { find } = rootStore.jobStore;

      find({ query: { jobNumber: jobNumber } });
    }
  }
];

export default routesList;
